<template>
  <div v-if="this.items.length > 0" class="mt-5">
    <div v-for="(items, key) in chunkedItems" class="row mb-4" :key="key">
      <div class="col-md-4" v-for="(item, index) in (items)" :key="index">
        <router-link :to="addHash(item.url)">
          <div class="card">
            <div class="position-relative">
              <img src="assets/img/play.png" class="img-fluid play"/>
              <img v-if="item.image !== null" class="card-img-top"
                   v-bind:src="item.image"
                   v-bind:alt="item.name" />
            </div>
            <div class="card-body">
              <h5 class="card-title">{{ item.name }}</h5>
              <div class="card-text" v-html="item.lead"/>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import _ from "lodash";

export default {
  name: "Module3",
  props: {
    items: {type: Array, required: true},
  },
  methods: {
    moment: function () {
      return moment();
    },
  addHash(url){
    return this.$addHashParameter(url);
  }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD');
    }
  },
  computed: {
    chunkedItems() {
      return _.chunk(this.items, 3)
    }
  }
}
</script>

<style scoped>

</style>